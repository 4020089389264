/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import './src/styles/global.scss'

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

export const wrapPageElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {element}
    </QueryClientProvider>
  )
}
